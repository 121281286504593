import React, { Component } from "react";
import "./Optin.css";

class Optin extends Component {
  // modal() {
  //   console.log("it was clicked");
  // }

  render() {
    return (
      <div className="optin">
        <p>
          Currently a parent of a child enrolled at the school? Click below to check if the information we have about you is correct.
        </p>
        <a href="https://3704.d6plus.co.za/register/" target="_blank">
          <button>Validate Registration Details</button>
        </a>
        <div id="modal">
          <div className="wrapper">
            <h3>Enter Your Email</h3>
            <div className="clearfix">
              <div className="col-8" />
              <div className="col-3" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Optin;
